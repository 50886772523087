<template>
  <div :class="['space-card-wrapper']">
    <div :class="['space-card-breadcrumb']">
      {{breadcrumbText}}
    </div>
    <div :class="['space-card-container']">
      <div :class="['space-card-title']">
        <div class="font-weight-black subtitle-1">
          {{name}}
        </div>
        <div v-if="progress==100">
          <v-icon >
            check
          </v-icon>
        </div>
      </div>
      <div v-if="progress!=null" :class="['space-card-progress-wrapper']">
          <v-progress-linear
            :value="this.progress"
            rounded
            color="success"
          />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      breadcrumbs: {
        type: String
      },
      name: {
        type: String,
      },
      progress: {
        type: Number,
        required: false
      }
    },

    data: () => ({
    }),

    computed: {
      breadcrumbText() {
        if (this.breadcrumbs) {
          return this.breadcrumbs.join(' > ')
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .space-card-title {
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    justify-content: space-between;
  }

  .space-card-breadcrumb {
    font-size: 10px;
  }
  .space-card-progress-wrapper {
    margin-top: 3px;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 5px;
  }
  .space-card-container {
    // border: 1px solid var(--v-white-darken3);
    // box-sizing: border-box;
    // border-radius: 4px;
    // background-color: var(--v-white-darken1);
  }

  .space-card-task-badges-container {
    float: right;
  }
  .space-card-wrapper {
    min-height: 50px;
    height: 100%;
    border-bottom: 1px solid var(--v-backgroundAccent-base);
  }
</style>