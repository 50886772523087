<template>
  <v-container fluid>
    <h3
      v-translate
      translate-context="MSpaceTimeView title"
    >
      Time & Space overview (BETA)
    </h3>
    <span>
      <small translate-context="MSpaceTimeView beta feature waring">
        This feature is still in development and early testing. Please send us your feedback
        through our <a href="https://forms.gle/jAYpsBpYsxRviXnQA">Feedback form</a> to help us
        find issues and improve Mestamaster
      </small>
    </span>
    <m-space-time
      v-bind:project="project"
      v-bind:spaceTimeData="spaceTimeData"
      />
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import MSpaceTime from '@/components/containers/MSpaceTime';
  export default {
    components: {
      MSpaceTime
    },
    props: {
    },

    data: () => ({
    }),
    computed: {
      ...mapGetters({
        spaces: 'project/spaces/spaces',
        spacesById: 'project/spaces/spacesById',
        todosByTaktWagon: 'project/todos/todosByTaktWagon',
        // Not a typo. let's start using takt with K
        takts: 'project/tacts/tacts',
        taktWagons: 'project/taktTrains/taktWagons',
        taktTrainsBySpaceId: 'project/taktTrains/taktTrainsBySpaceId',
        project: 'project/project/project',
      }),
      // MIGRATE ALL THESE CALCULATIONS OUTSIDE THE SPACE TIME CONTAINER
      // ALL THESE CALCULATIONS SHOUOLD HAPPEN IN THE VIEW, AND BE PASSED AS
      // PROPS TO THE COMPONENT.
      spaceTimeData() {
        const buildSpaceWagons = (todosByTaktWagon, taktWagons) => {
          return taktWagons.map((wagon)=> {
            return {
              name: wagon.name, 
              start: wagon.startsAt,
              end: wagon.endsAt,
              tasks: todosByTaktWagon[wagon.id],
            }
          });
        }

        let data_rows = []
        Object.values(this.spacesById).sort((a, b) => { a.order > b.order } ).forEach((space)=> {
          return (this.taktTrainsBySpaceId[space.id] || []).forEach((train)=> {
            data_rows.push({
                  id: train.id,
                  name: space.address,
                  gtArray: buildSpaceWagons(this.todosByTaktWagon, this.taktWagons.filter(wagon => wagon.taktTrainId === train.id))
              })
            })
        })
        return data_rows
      },
    },
    methods: {
      ...mapActions({
          loadSpaces: 'project/spaces/loadSpaces',
          loadTodos: 'project/todos/loadTodos',
          loadTacts: 'project/tacts/loadTacts',
          loadTaktTrains: 'project/taktTrains/loadTaktTrains',
          loadTaktWagons: 'project/taktTrains/loadTaktWagons'
      }),
    },

    async created() {
      this.loading = true;

      await Promise.all([
        this.loadTacts(),
        this.loadTaktTrains(),
        this.loadTaktWagons(),
        this.loadSpaces(),
        this.loadTodos(),
      ]);

      setTimeout(() => {
        this.loading = false;
      });
    },
  }
</script>

<style lang="sass" scoped>
</style>