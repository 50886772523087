var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-slider',{attrs:{"hint":"Zoom in or out to see more or less takts","max":_vm.minimumWidth * 3,"min":_vm.minimumWidth,"append-icon":"zoom_in","prepend-icon":"zoom_out"},on:{"click:append":_vm.zoomIn,"click:prepend":_vm.zoomOut},model:{value:(_vm.cellWidth),callback:function ($$v) {_vm.cellWidth=$$v},expression:"cellWidth"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-switch',{staticClass:"mt-0",attrs:{"label":'Expand wagons'},on:{"change":function($event){return _vm.expandToggled()}},model:{value:(_vm.expanded),callback:function ($$v) {_vm.expanded=$$v},expression:"expanded"}})],1)],1),_c('v-gantt-chart',{attrs:{"startTime":_vm.startTime,"endTime":_vm.endTime,"datas":_vm.spaceTimeData,"customGenerateBlocks":true,"scale":_vm.chartScale,"cellHeight":_vm.cellHeight,"cellWidth":_vm.cellWidth,"showCurrentTime":"","titleWidth":150},scopedSlots:_vm._u([{key:"timeline",fn:function(ref){
var day = ref.day;
var getTimeScales = ref.getTimeScales;
return [_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(day.format('DD'))+" ")])]}},{key:"left",fn:function(ref){
var data = ref.data;
return [_c('m-space-card',{attrs:{"name":data.name}})]}},{key:"title",fn:function(){return [_c('div')]},proxy:true},{key:"block",fn:function(ref){
                              var data = ref.data;
                              var getPositonOffset = ref.getPositonOffset;
                              var getWidthAbout2Times = ref.getWidthAbout2Times;
                              var isInRenderingTimeRange = ref.isInRenderingTimeRange;
                              var startTimeOfRenderArea = ref.startTimeOfRenderArea;
                              var isAcrossRenderingTimeRange = ref.isAcrossRenderingTimeRange;
                              var endTimeOfRenderArea = ref.endTimeOfRenderArea;
return _vm._l((data.gtArray),function(item){return (isInRenderingTimeRange(item.start)||isAcrossRenderingTimeRange(item.start,item.end)
                    ||isInRenderingTimeRange(item.end))?_c('div',{key:item.id,staticClass:"myBlockContainer",style:({position:'absolute',
                      left:getPositonOffset(item.start)+ 2 + 'px',
                      width:getWidthAbout2Times(item.start,item.end) - 2 +'px'})},[_c('m-waggon',_vm._b({attrs:{"expanded":_vm.expanded}},'m-waggon',item,false))],1):_vm._e()})}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }