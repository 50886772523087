<template>
  <div :class="['waggon-container']">
    <div :class="['waggon-title']">
      {{this.name}}
    </div>
  
    <div v-if="!expanded" :class="['d-flex', 'waggon-task-badges-container']">
      <div :class="['badge-wrapper']" v-for="task in this.orderedTasks" :key="task.id">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on">
                <m-todo-badge 
                :state="task.state"
                :current-takt="currentTakt"
                :scheduled-takt="task.takt"
                :estimated-time="task.estimatedDuration"
              />
            </div>
          </template>
          <span>{{task.taskName}} - {{task.teamName}} - {{ Math.round((task.estimatedDuration / 60) * 10) / 10 }} h</span>
        </v-tooltip>
      </div>
    </div>
    <div v-if="expanded" :class="['expanded-waggon']">
      <div :class="['task-detail-view']" v-for="task in this.orderedTasks" :key="task.id">
        <div :class="['display-row', 'task-row']">
          <!-- BADGE COLUMN -->
          <div class="task-badge">
            <m-todo-badge 
              :state="task.state"
              :current-takt="currentTakt"
              :scheduled-takt="task.takt"
              :estimated-time="task.estimatedDuration"
              />
            </div>
            <!-- DETAILS COLUMN -->
            <div class="task-data">
              <div class="task-title">
                {{task.taskName}}
              </div>
              <div class="display-row task-details">
                <div>
                    <v-icon class="task-details">
                      access_time
                    </v-icon>
                    {{ Math.round((task.estimatedDuration / 60) * 10) / 10 }} h
                </div>
                <div class="assigned-team">
                  <v-icon class="task-details">
                    people_outline
                  </v-icon> 
                    {{task.teamName}}
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // TODOS
  // - Create component for stacked badges and logic for stacking
  import MTodoBadge from '@/components/atoms/todos/MTodoBadge';
  export default {
    components: { MTodoBadge },
    props: {
      name: {
        type: String,
      },
      tasks: {
        type: Array,
        default: () => ([])
      },
      currentTakt: {
        type: Number,
      },
      expanded: {
        type: Boolean,
        default: false
      },
    },

    data: () => ({
      // Used to order the tasks for their presentation
      stateOrderWeight: {
        started: 1,
        enabled: 100,
        started: 1,
        completed: 200,
        interrupted: 0,
        skipped: 1000,
        deleted: 1000,
      }
    }),

    computed: {
      orderedTasks() {
        return [...this.tasks].sort((a, b) => {
          return this.stateOrderWeight[b.state] - this.stateOrderWeight[a.state]
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .waggon-title {
    padding-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    font-weight: bold;
  }
  .badge-wrapper {
    margin-right: 1px;
    margin-left: 1px;
  }
  .waggon-container {
    border: 1px solid var(--v-white-darken3);
    box-sizing: border-box;
    background-color: var(--v-backgroundAccent-base);
  }

  .expanded-waggon {
    overflow-y: auto;
    overflow-x: hidden;
  }


  .waggon-task-badges-container {
    justify-content: flex-end;
  }

  .display-row {
    display: flex;
  }

  .task-data {
    padding-left: 5px;
  }

  .task-row {
    margin-top: 10px;
  }
  .task-title {
    font-size: 80%;
  }

  .task-details {
    font-size: 70%;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .task-detail-view {
    padding-left: 5px;
  }
  .assigned-team {
    padding-left: 5px;
  }
</style>