<template>
  <div>
    <v-row class="">
      <v-col cols="10">
        <v-slider
          hint="Zoom in or out to see more or less takts"
          v-bind:max="minimumWidth * 3"
          v-bind:min="minimumWidth"
          append-icon="zoom_in"
          prepend-icon="zoom_out"
          v-model="cellWidth"
          @click:append="zoomIn"
          @click:prepend="zoomOut"
        ></v-slider>
      </v-col>
      <v-col cols="2">
        <v-switch
          class="mt-0"
          v-model="expanded"
          :label="'Expand wagons'"
          @change="expandToggled()"
        ></v-switch>
      </v-col>
    </v-row>
    <v-gantt-chart v-bind:startTime="startTime"
                  v-bind:endTime="endTime"
                  v-bind:datas="spaceTimeData"
            :customGenerateBlocks="true"
            v-bind:scale="chartScale"
            v-bind:cellHeight="cellHeight"
            v-bind:cellWidth="cellWidth"
            showCurrentTime
            :titleWidth="150">
        <template v-slot:timeline="{day , getTimeScales}">
          <span class="caption">
            {{day.format('DD')}}
          </span>
        </template>
        <!-- <template v-slot:markLine="{timeConfig, getPosition}">
            <TestMarkline :timeConfig="timeConfig" :getPosition="getPosition"></TestMarkline>
        </template> -->
        <template v-slot:left="{data}">
          <m-space-card v-bind:name="data.name"></m-space-card>
        </template>
        <template v-slot:title>
          <div>
          </div>
        </template>
        <template v-slot:block="{data,
                                getPositonOffset,
                                getWidthAbout2Times,
                                isInRenderingTimeRange,
                                startTimeOfRenderArea,
                                isAcrossRenderingTimeRange,
                                endTimeOfRenderArea}">
          <div class="myBlockContainer"
                v-for="item in data.gtArray"
                v-if="isInRenderingTimeRange(item.start)||isAcrossRenderingTimeRange(item.start,item.end)
                      ||isInRenderingTimeRange(item.end)"
                :key="item.id"
                :style="{position:'absolute',
                        left:getPositonOffset(item.start)+ 2 + 'px',
                        width:getWidthAbout2Times(item.start,item.end) - 2 +'px'}">
            <m-waggon v-bind="item" v-bind:expanded="expanded"></m-waggon>
          </div>
        </template>
    </v-gantt-chart>
  </div>
</template>

<script>
  import Vue from "vue";
  import { mapGetters, mapActions } from 'vuex';
  import MWaggon from '@/components/atoms/waggon/MWaggon';
  import MSpaceCard from '@/components/atoms/spaceCard/MSpaceCard';
  import vGanttChart from '@mestamaster/v-gantt-chart';

  export default {
    props: {
      project: {
        required: true,
      },
      spaceTimeData: {
        required: true,
      },
    },
    components: {
      vGanttChart,
      MWaggon,
      MSpaceCard
    },

    data() {
      return {
        cellWidth: this.minimumWidth,
        expanded: false
      }
    },
    
    methods: {
      zoomOut () {
        this.cellWidth = (this.cellWidth - 10) || 10
      },
      zoomIn () {
        this.cellWidth = (this.cellWidth + 10) || 100
      },
      expandToggled () {
        if (this.expanded && this.cellWidth < this.minimumWidth){
          this.cellWidth = this.minimumWidth
        }
      }
    },

    computed: {
      chartScale() {
        return this.project.tactDuration < 1 ? 720 : 1440
      },
      
      minimumWidth() {
        if (this.expanded){
          return 200 / ((this.project.tactDuration * 24 * 60) / this.chartScale)
        } else {
          return 90 / ((this.project.tactDuration * 24 * 60) / this.chartScale)
        }
      },

      cellHeight() {
        return this.expanded ? 200 : 50
      },
      startTime() {
        return this.project.startsAt
      },
      endTime() {
        return this.project.endsAt || this.project.startsAt
      },
    },
  }
</script>

<style lang="scss">
  // Styles are super specific to ensure they are
  // picked instead of the defaults.
  #app .gantt-chart .gantt-container .gantt-header {
    background: transparent;
  }
  #app .gantt-chart .gantt-container .gantt-leftbar-wrapper {
    background: transparent;
  }
  #app .gantt-chart .gantt-container .gantt-header-title {
    background: transparent;
  }
  #app .gantt-chart .gantt-container .gantt-leftbar {
    background: transparent;
  }
</style>