<template>
    <div :class="['todo-badge-circular-container', this.color, this.sizeClass]">
      <v-icon :class="['icon-container']">
        {{this.icon}}
      </v-icon>
    </div>
</template>

<script>
  export default {
    props: {
      state: {
        type: String,
        required: true,
        default: "enabled"
      },
      currentTakt: {
        type: Number,
      },
      scheduledTakt: {
        type: Number,
      },
      completedTakt: {
        type: Number,
      },
      estimatedTime: {
        type: Number,
      },
      elapsedTime: {
        type: Number,
      },
      size: {
        type: String,
        default: "sm"
      }
    },

    data: () => ({
      stateToIcon: {
        enabled: null,
        started: "play_arrow",
        completed: "check",
        interrupted: "warning",
        skipped: null,
        deleted: null,
      }
    }),

    computed: {
      icon() {
        return this.stateToIcon[this.state]
      },
      sizeClass() {
        if (["sm", "md"].includes(this.size)) {
          return `todo-${this.size}-badge`
        }
        else {
          return 'todo-sm-badge'
        }
      },
      inProgressColor() {
        if (this.currentTakt > this.scheduledTakt) {
          return "todo-badge-critical"
        }
        if (this.elapsedTime > this.estimatedTime) {
          return "todo-badge-warning"
        }
        return "todo-badge-info-light"
      },
      todoColor() {
        if (this.currentTakt > this.scheduledTakt) {
          return "todo-badge-critical"
        }
        return "todo-badge-neutral"
      },
      completedColor() {
        if (this.completedTakt > this.scheduledTakt) {
          return "todo-badge-success-warning"
        }
        return "todo-badge-success"
      },
      color() {
        if (this.state === "enabled") {
          return this.todoColor
        }
        if (this.state === "started") {
          return this.inProgressColor
        }
        if (this.state === "interrupted") {
          return "todo-badge-critical"
        }
        if (this.state === "completed") {
          return this.completedColor
        }

        return "todo-badge-neutral"
      },
    }
  }
</script>

<style lang="scss" scoped>
  .icon-container {
      font-size: 15px;
  };

  .todo-sm-badge {
    height: 20px;
    width: 20px;
  }

  .todo-md-badge {
    height: 25px;
    width: 25px;
  }

  .todo-badge-circular-container {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    .v-icon {
      color: white;
      height: 100%;
    }
  }
  .todo-badge-critical {
    background-color: var(--v-error-base);
    border: 1px solid #ff0000;
  }
  .todo-badge-warning {
    background-color: var(--v-warning-base);
    border: 1px solid var(--v-black-base);
  }
  .todo-badge-info {
    background-color: var(--v-info-base);
    border: 1px solid var(--v-black-base);
  }
  .todo-badge-success {
    background-color: var(--v-success-base);
    border: 1px solid var(--v-black-base),
  }

  .todo-badge-info-light {
    background-color: var(--v-info-lighten2);
    border: 1px solid var(--v-black-base),
  }

  .todo-badge-success-warning {
    background-color: var(--v-warning-darken2);
    border: 1px solid var(--v-black-base);
    color: #FFFF;
  }
  .todo-badge-neutral {
    background-color: var(--v-white-base);
    border: 1px solid var(--v-black-base);
  }
</style>